import { useUserStore } from "~/stores/userStore";
import useRedfast from "~/composables/useRedfast";

export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) return;

  const auth = useNuxtApp().$auth;
  const { loginWithRedirect, getAccessTokenSilently } = auth;
  const userStore = useUserStore();
  const { fetchRedfastMetaData } = useRedfast();

  await auth.checkSession();

  if (!auth.isAuthenticated.value) {
    userStore.logoutUser();
    await loginWithRedirect({
      appState: {
        target: to.fullPath,
      },
    });
  } else {
    const authToken = await getAccessTokenSilently({ detailedResponse: true });
    // Load user data if needed
    await userStore.loginUser(authToken.id_token);
    userStore.setToken(authToken.id_token);

    // Fetch Redfast metadata after user is authenticated
    await fetchRedfastMetaData();

    if (
      !userStore.isProfileComplete &&
      userStore.profileId &&
      !to.path.includes("/my/profile")
    ) {
      return navigateTo("/my/profile");
    }
  }
});
