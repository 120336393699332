import type { RedfastMetadataResponse } from "~/types/Redfast";

export default function useRedfast() {
  const { $goodbits } = useNuxtApp();

  const fetchRedfastMetaData = async (): Promise<void> => {
    // Check if we already have a Redfast ID in localStorage
    const existingRedfastId = localStorage.getItem("redfast-id");
    if (existingRedfastId) {
      return;
    }

    try {
      const {
        data: { accountCode },
      } = await $goodbits<RedfastMetadataResponse>(
        "/subscriptions/v3/redfast-metadata",
      );

      if (accountCode) {
        localStorage.setItem("redfast-id", accountCode);
      }
    } catch (err: any) {
      console.error("Error fetching Redfast metadata:", err);
    }
  };

  return {
    fetchRedfastMetaData,
  };
}
